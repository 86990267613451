* {
    font-family: 'Roboto', sans-serif;
}

body {
    float: left;
    width: 100%;
    height: 100vh;
    position: relative;
    top: 0;
    left: 0;
    background: #eee;
}

.sidebar-mobile {
    width: 360px;
}
.sidebar {
    width: 140px;
}
.sidebar,
.sidebar-mobile {
    z-index: 11;
    position: fixed;
    top: 0;
    height: 100vh;
    padding-bottom: 60px;
    background-color: #fff;
    overflow-y: auto;
    background: #000;
    transition: all 0.2s ease;
}

.tooltip-portal-sidebar {
    margin-left: 80px;
}

.text-blue .react-select__single-value,
.text-blue .react-select__multi-value__label {
    color: #0779ce;
}

.blue {
    color: #106ba3 !important;
}

.sidebar li,
.sidebar-mobile li {
    list-style-type: none;
    box-sizing: border-box;
}

.logo {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 50px;
    border-left: 0;
}
.logo img {
    transition: all 0.3s ease;
    max-width: 110px;
}
.list-menu:hover,
.sidebar-mobile .list-menu:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
/* .list-menu:hover:after, .sidebar-mobile .list-menu:hover:after{
   content:'';
    height: 100%;
    z-index: 99999;
    position:absolute;
    float:left;
    width: 4px;
    left: 0;
    top:0;
    background: #3da7c3;
} */
.list-menu,
.sidebar-mobile .list-menu {
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 16px;
    text-align: left;
    width: 100%;
    position: relative;
    float: left;
}

.list-menu:hover,
.sidebar-mobile .list-menu:hover {
    background: #3da7c3;
}

.active {
    background: #3da7c3;
}

.list-menu a,
.sidebar-mobile .list-menu a {
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    width: 100%;
    overflow: hidden;
    position: relative;
    float: left;
    height: 100%;
    padding: 10px 15px;
}
.list-menu p {
    height: 25px;
    text-align: center;
    margin-bottom: 0;
}
.sub-list-menu {
    margin-top: 10px;
    background: #fff !important;
}

.sub-list-menu li:hover {
    background: #eee !important;
}
.sub-list-menu * {
    color: #444f5b !important;
}
.sub-list-menu {
    padding-top: 10px;
    padding-bottom: 10px;
}

.list-menu a img {
    width: 20px;
}

.sidebar ul,
.sidebar-mobile ul {
    position: relative;
    float: left;
    padding: 0;
    text-align: right;
    width: 100%;
}

.footer {
    font-size: 12px;
    position: absolute;
    float: left;
    width: 100%;
    text-align: center;
    top: 90%;
    margin-bottom: 25px;
}

.badge {
    background: #3da7c3;
    color: #fff;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 13px;
    margin-left: 10px;
}
.badge-info {
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    padding: 2px 5.5px;
    font-weight: 400;
    top: -3px;
    cursor: help;
    position: relative;
    font-size: 12px;
}

.profile-bar {
    padding: 10px 25px 0px;
    top: 0;
    background: #fff;
    color: #54616f;
    font-size: 0.9rem;
    width: 100%;
    position: fixed;
    text-align: right;
    box-sizing: border-box;
    z-index: 10;
    min-height: 48px;
}

.btn-config {
    color: #54616f !important;
    font-size: 16px;
    top: 5px;
    padding: 3px;
    margin-left: 6px;
}

.profile-bar .right .img {
    position: relative;
}

.profile-bar a {
    padding: 0;
    color: #54616f;
    text-decoration: none;
    font-size: 1.5rem;
}

@media screen and (max-width: 468px) {
    .button-top {
        width: 100%;
    }
    .title-section {
        width: 100%;
        text-align: center;
    }
}
@media screen and (min-width: 960px) {
    .pr-0 {
        padding-right: 0;
    }
    .pl-0 {
        padding-left: 0;
    }
}
@media screen and (max-width: 960px) {
    .pr-0,
    .pl-0 {
        padding: 0 !important;
    }
    .sidebar {
        display: none;
    }
    .container {
        width: 100% !important;
        left: 0px !important;
    }
}

.container {
    position: relative;
    float: left;
    top: 25px;
    width: calc(100% - 140px);
    left: 140px;
    box-sizing: border-box;
    padding: 30px 40px;
    max-width: none !important;
    transition: all 0.2s ease;
}

.container-collapse {
    width: calc(100% - 50px) !important;
    left: 50px !important;
}

.sidebar-collapse {
    width: 50px !important;
}

.sidebar-collapse .logo img {
    width: 30px;
}
.sidebar-collapse .text-link {
    display: none !important;
}

.icon-profile-bar img {
    max-width: 18px;
}

.top-card h4 {
    color: #fff;
    position: relative;
    float: left;
    font-size: 20px;
    font-weight: 300;
    padding-top: 13px;
    box-sizing: border-box;
    width: 100%;
}
.top-card {
    width: 100%;
    min-height: 50px;
    padding: 0px 15px;
    background: #3da7c3;
}

.buttons-top-container {
    height: 100%;
    padding: 5px;
    text-align: left;
}
.right a {
    position: relative;
    left: 0;
    padding: 0 5px;
    float: left;
}
.right .salir img {
    position: absolute;
    top: 5px;
}
.right {
    position: relative;
    float: right;
}
.left {
    position: relative;
    float: left;
}
.icon-menu {
    font-size: 20px !important;
    color: #888 !important;
}
@media screen and (min-width: 1024px) {
    .icon-menu {
        display: none;
    }
}
.title-section {
    display: inline-block;
    text-align: left;
    font-size: 1.9rem;
    margin-right: 30px;
    font-weight: 400;
    color: #fff;
}
.overlay-form {
    z-index: 9999999 !important;
}

.table thead th {
    border-bottom: none !important;
}

.table tbody td {
    border: none !important;
}

.table thead th,
.table tbody td {
    padding: 13px 5px;
}

table td {
    font-size: 14px !important;
}

.button-top {
    position: relative;
    float: left;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #1f70a1;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    float: right;
}

.button-top:hover {
    opacity: 0.98;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14);
    color: #fff;
}

.card {
    position: relative;
    margin: 0rem 0 1rem 0;
    background-color: #fff;
    transition: box-shadow 0.25s;
    border-radius: 2px;
    width: 100%;
    min-height: 100px;
    border: none !important;
}

.action {
    position: relative;
    float: left;
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
}

.copyright {
    text-decoration: none !important;
    color: #536170;
}
.copyright :hover {
    color: #536170;
}

th {
    text-align: center;
    font-weight: 400 !important;
    font-size: 17px;
}

.content_table {
    padding: 0 3px;
}
.action-card {
    padding: 0px 15px;
    max-height: 75px;
}
.action-card button {
    margin-right: 10px;
}

.content_table td {
    font-size: 15px;
}
.table th {
    font-size: 15px;
}
.bp3-popover-wrapper,
.bp3-popover-wrapper span,
.bp3-popover-wrapper div {
    outline: none !important;
}

.icon-fisica {
    margin-right: 10px;
}

.badge2 {
    position: relative;
    float: left;
    font-size: 12px;
    background: #3da7c3;
    color: #fff;
    line-height: 8px;
    width: 40px;
    left: calc(50% - 20px);
    height: 20px;
    text-align: center;
    padding-top: 6px;
    border-radius: 10px;
    margin-left: 0px;
    z-index: 2;
}

a {
    text-decoration: none !important;
    color: #444;
}

.content-roles-table {
    max-width: 100%;
    text-align: justify;
}
.role-span {
    font-size: 13px;
    color: #666;
}
.content-related-participants {
    z-index: 9999;
    position: absolute;
    float: right;
    right: 20px;
    width: 600px;
    padding: 10px;
    background: #fff;
    text-align: center;
    box-shadow: 0 0 5px #444;
    border-radius: 3px;
    animation: fadein 0.5s;
}
.content-related-participants table td {
    font-size: 13px;
    padding: 10px 0;
}

.fade-in {
    animation: fadein 0.5s;
}

.bg-white {
    background: #fff !important;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.content-table {
    height: 400px;
    overflow-y: auto;
}

.modal-form .bp3-popover-target,
.modal-form .bp3-input-group {
    width: 100%;
}
.modal-form-mail .bp3-popover-target,
.modal-form-mail .bp3-input-group {
    width: 100%;
}

.label-form {
    width: 100%;
    padding: 5px 0px;
    font-weight: 400 !important;
}

label {
    font-weight: 400;
}

.required {
    color: #f00;
}

.modal-form {
    padding: 0px;
    width: 480px;
}
.modal-form-mail {
    padding: 0px;
    width: 80%;
    max-width: none !important;
}

.body-modal {
    padding: 0 15px 5px;
}

.overlay-form {
    background: #4d596687;
}

.modal-form button svg {
    fill: #fff !important;
}
.modal-form-mail button svg {
    fill: #fff !important;
}
.modal-form label {
    font-weight: 400;
}
.modal-form-mail label {
    font-weight: 400;
}

.title-modal button {
    top: 7px !important;
}

.btn-primary {
    background-color: #3da7c3 !important;
    border-color: #3da7c3 !important;
    transition: all 0.3s ease;
}
.btn-primary:hover {
    opacity: 0.8;
}

.title-modal {
    background: #3da7c3;
    color: #fff;
    margin: 0;
    padding: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
}

.cancel-button {
    text-decoration: none;
    color: #222;
    border: none;
    background-color: #fff;
    text-align: center;
    letter-spacing: 0.5px;
    transition: 0.2s ease-out;
    cursor: pointer;
    font-size: 16px;
    padding-right: 25px;
    padding-left: 25px;
    height: 54px;
    line-height: 54px;
    margin-right: 10px;
    outline: none;
}
.create-button {
    height: 54px;
    outline: none;
    border: none;
    line-height: 54px;
    text-decoration: none;
    color: #fff;
    background-color: #3da7c3;
    text-align: center;
    letter-spacing: 0.5px;
    transition: 0.2s ease-out;
    cursor: pointer;
    font-size: 16px;
    padding-right: 25px;
    padding-left: 25px;
    margin-right: 25px;
}

.create-button-small {
    font-size: 13px;
    outline: none;
    border: none;
    line-height: 30px;
    text-decoration: none;
    color: #fff;
    background-color: #3da7c3;
    text-align: center;
    letter-spacing: 0.5px;
    transition: 0.2s ease-out;
    cursor: pointer;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: 25px;
}

.more-options {
    color: #aaa;
    background: #fff;
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 0;
    font-size: 16px;
}

.geosuggest__suggests {
    position: absolute;
    float: left;
    width: 100%;
    left: 0;
    top: 35px;
    padding: 0;
    background: #fff;
    z-index: 999;
    text-align: left;
}
.geosuggest {
    position: relative;
}
.geosuggest__suggests ul {
    padding: 0;
}

.geosuggest__suggests li {
    list-style-type: none;
    border: solid 1px #eee;
    padding: 10px;
    cursor: pointer;
}
.geosuggest__suggests li:hover {
    background: #efefef;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}

select {
    -webkit-appearance: none !important;
    appearance: none !important;
}
.DayPicker-Day--outside {
    color: #ccc !important;
}

.DayPicker-Weekday,
.DayPicker-Day {
    width: calc(100% / 7);
    position: relative;
    float: left;
    padding: 10px;
    transition: all 0.2s ease;
    cursor: pointer;
}

.DayPicker-Day:hover {
    background: #467fcf;
    color: #fff;
}

.bp3-dateinput-popover {
    background: #fff;
    z-index: 100 !important;
}

.bp3-transition-container {
    z-index: 999999;
}

.bp3-popover-arrow {
    z-index: 10 !important;
}
.bp3-popover-content {
    position: relative;
    float: left;
    z-index: 12;
}

.forgot-password * {
    color: #fff;
}

.table-view td {
    padding: 7px !important;
}

.table-view thead * {
    color: #fff;
}
.table-view thead th {
    padding: 8px !important;
}
.table-view thead {
    background-color: #444;
}

.table-list thead {
    background-color: #3da7c3;
}
.table-list thead th {
    padding: 10px !important;
}

.table-list thead * {
    color: #fff;
}

.container-login {
    background: #2369a0; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2369a0, #04517b); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2369a0, #04517b); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    height: 100vh;
}

.field-login {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    height: 3rem !important;
    width: 100%;
    margin: 0 0 25px 0;
    padding: 25px 10px;
    font-size: 16px;
    box-shadow: none;
    box-sizing: border-box;
    transition: all 0.3s;
}

.btn {
    font-size: 14px;
    border-radius: 3px;
}

.btn-large {
    width: 100%;
    text-decoration: none;
    color: #fff;
    background-color: #3da7c3;
    text-align: center;
    letter-spacing: 0.5px;
    transition: 0.2s ease-out;
    cursor: pointer;
    font-size: 16px;
    padding-right: 25px;
    padding-left: 25px;
    height: 54px;
    line-height: 54px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.registro .input-field {
    padding: 0 15px;
    position: relative;
    margin-top: 1.5rem;
}
.registro {
    position: absolute;
    width: 400px;
    height: 550px;
    top: 50%;
    left: 50%;
    margin-left: -200px;
    margin-top: -275px;
}

.registro .card {
    position: relative;
    margin: 0.5rem 0 1rem 0;
    background-color: #fff;
    transition: box-shadow 0.25s;
    border-radius: 2px;
    padding: 20px;
    padding-top: 30px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
}

.logo-initial {
    margin-bottom: 20px;
}

.input-select-form {
    max-height: 50px;
    z-index: 15;
}

.btn-mega {
    border: solid 1px #ccc;
    border-radius: 0px;
    display: block;
    outline: 0;
    text-align: center;
    padding: 0 2rem;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    padding: 40px;
    padding-top: 50px;
    padding-bottom: 65px;
    transition: 0.2s;
    width: 90%;
    left: 5%;
    position: relative;
    height: 220px;
    margin-bottom: 50px;
    margin-top: 30px;
    cursor: pointer;
}

.btn-mega:hover {
    background: #3da7c3;
    color: #fff;
    transition: 0.2s;
}

.icon {
    pointer-events: none;
}
.active-limit {
    color: #fff !important;
}

.btn-mega:hover > small {
    pointer-events: none;
    color: #fff;
}

.btn-mega h5 {
    pointer-events: none;
    font-size: 18px;
    font-weight: 400;
}
.btn-mega small {
    pointer-events: none;
    font-size: 13px;
    font-weight: 400;
    color: #777;
}

.icon-participant-list {
    margin-right: 10px;
}

.active-step-budget {
    border-bottom: solid 2px #54616f !important;
}

.budget-steper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 10px 20px;
}

.budget-steper li {
    cursor: pointer;
    font-weight: 500;
    padding: 10px 0;
    transition: all 0.3s ease;
    border-bottom: solid 2px #fff;
    color: #54616f;
    text-align: center;
    text-decoration: none;
    list-style-type: none;
}

.payment-concept-row {
    border-bottom: solid 1px #ccc;
    font-size: 16px;
    padding: 5px;
}
.p-0 {
    padding: 0 !important;
}
.my-4 {
    margin-top: 20px !important;
}
.py-8 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}
.py-7 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
}
.py-6 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}
.py-5 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
}
.py-4 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}
.py-3 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.py-2 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.py-1 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.pt-8 {
    padding-top: 40px !important;
}
.pt-7 {
    padding-top: 35px !important;
}
.pt-6 {
    padding-top: 30px !important;
}
.pt-5 {
    padding-top: 25px !important;
}
.pt-4 {
    padding-top: 20px !important;
}
.pt-3 {
    padding-top: 15px !important;
}
.pt-2 {
    padding-top: 10px !important;
}
.pt-1 {
    padding-top: 5px !important;
}
.pb-8 {
    padding-bottom: 40px !important;
}
.pb-7 {
    padding-bottom: 35px !important;
}
.pb-6 {
    padding-bottom: 30px !important;
}
.pb-5 {
    padding-bottom: 25px !important;
}
.pb-4 {
    padding-bottom: 20px !important;
}
.pb-3 {
    padding-bottom: 15px !important;
}
.pb-2 {
    padding-bottom: 10px !important;
}
.pb-1 {
    padding-bottom: 5px !important;
}
.px-5 {
    padding-left: 25px !important;
    padding-right: 25px !important;
}
.px-4 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.px-3 {
    padding-left: 15px !important;
    padding-right: 15px !important;
}
.px-2 {
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.px-1 {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.p-5 {
    padding: 25px !important;
}

.p-4 {
    padding: 20px !important;
}

.p-3 {
    padding: 15px !important;
}

.p-2 {
    padding: 10px !important;
}

.p-1 {
    padding: 5px !important;
}
.w-100 {
    width: 100%;
}
.icon-trash {
    top: 40px;
    position: relative;
}
.content-payment-methods div {
    padding: 0 !important;
}
.bg-grey {
    background: #dedede;
}

.content-chart {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 5px 0;
}

.c3-tooltip-container {
    background: rgb(255, 255, 255);
    box-shadow: rgb(170, 170, 170) 0px 0px 3px;
    padding: 15px;
    z-index: 200;
}

.c3-tooltip-container .value {
    padding: 0 5px;
    font-weight: bold;
}

.c3-target text {
    display: none !important;
}

.add-plus-button {
    text-align: right;
    font-size: 34px;
    font-weight: 300;
}

.my-3 {
    margin-top: 15px;
}

.detail-participant {
    padding: 5px;
    width: 100%;
}

.detail-participant .row {
    padding: 10px 0;
}

.detail-participant small {
    color: #999;
}
.detail-participant .title {
    padding-bottom: 10px !important;
}

.alert-success {
    animation: fadein 0.3s;
}

.icon-action {
    padding: 10px;
    animation: fadein 0.3s;
}

.pagination-item {
    padding: 5px 10px 5px;
    font-size: 17px;
}

.current-page {
    cursor: default;
    color: rgb(255, 255, 255) !important;
    background: #3da7c3;
    border-radius: 3px;
}

.t-head {
    text-align: center;
    font-weight: 400 !important;
    border-bottom: solid 1px #444 !important;
    font-size: 16px;
}
.t-sub-head {
    text-align: center;
    font-weight: 400 !important;
    border-bottom: solid 1px #444 !important;
    font-size: 14px;
}
.list div {
    padding: 10px;
    text-align: center;
}

.dummy-loading {
    width: 100%;
    margin: 6.5px 0;
    padding: 5px;
    background: #f0f0f0;
    animation: dummy 0.75s infinite;
    border-radius: 3px;
}

@keyframes dummy {
    0% {
        background: linear-gradient(90deg, #f0f0f0 0%, #fafafa 5%, #fafafa 15%, #f0f0f0 30%);
    }
    7% {
        background: linear-gradient(90deg, #f0f0f0 5%, #fafafa 10%, #fafafa 20%, #f0f0f0 35%);
    }
    14% {
        background: linear-gradient(90deg, #f0f0f0 10%, #fafafa 15%, #fafafa 25%, #f0f0f0 40%);
    }
    21% {
        background: linear-gradient(90deg, #f0f0f0 15%, #fafafa 20%, #fafafa 30%, #f0f0f0 45%);
    }
    28% {
        background: linear-gradient(90deg, #f0f0f0 20%, #fafafa 25%, #fafafa 35%, #f0f0f0 50%);
    }
    35% {
        background: linear-gradient(90deg, #f0f0f0 25%, #fafafa 30%, #fafafa 40%, #f0f0f0 55%);
    }
    42% {
        background: linear-gradient(90deg, #f0f0f0 30%, #fafafa 35%, #fafafa 45%, #f0f0f0 60%);
    }
    49% {
        background: linear-gradient(90deg, #f0f0f0 35%, #fafafa 40%, #fafafa 50%, #f0f0f0 65%);
    }
    56% {
        background: linear-gradient(90deg, #f0f0f0 40%, #fafafa 45%, #fafafa 55%, #f0f0f0 70%);
    }
    63% {
        background: linear-gradient(90deg, #f0f0f0 45%, #fafafa 50%, #fafafa 60%, #f0f0f0 75%);
    }
    70% {
        background: linear-gradient(90deg, #f0f0f0 50%, #fafafa 55%, #fafafa 65%, #f0f0f0 80%);
    }
    77% {
        background: linear-gradient(90deg, #f0f0f0 55%, #fafafa 60%, #fafafa 70%, #f0f0f0 85%);
    }
    85% {
        background: linear-gradient(90deg, #f0f0f0 60%, #fafafa 65%, #fafafa 75%, #f0f0f0 90%);
    }
    92% {
        background: linear-gradient(90deg, #f0f0f0 65%, #fafafa 70%, #fafafa 80%, #f0f0f0 95%);
    }
    100% {
        background: linear-gradient(90deg, #f0f0f0 70%, #fafafa 75%, #fafafa 85%, #f0f0f0 100%);
    }
}

.checkbox span {
    font-size: 16px;
    margin-left: 20px;
}
.checkbox input {
    margin-left: 0;
    margin-right: 0;
}

.current-tab {
    border-bottom: solid 3px #3da7c3 !important;
}
.content-tab {
    border-bottom: solid 1px #f3f3f3;
}

.tab {
    cursor: pointer;
    border-bottom: solid 3px transparent;
    background: #fff;
    color: #54616f;
    font-size: 15px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 5px;

    transition: all 0.3s ease;
}

.tab-middle {
    border-left: #eee solid 1px;
    border-right: #eee solid 1px;
}
.tab-first {
    border-right: #eee solid 1px;
}
.tab-last {
    border-left: #eee solid 1px;
}

.pointer {
    cursor: pointer;
}

.left {
    position: relative;
    float: left;
}

.dropdown-caret {
    position: relative;
    float: left;
    font-size: 22px;
    font-weight: 300;
    transition: all 0.3s ease;
}
.caret-down {
    transform: rotate(90deg);
}

.small {
    padding: 8px 10px;
}

.mt-5 {
    margin-top: 25px;
}
.mt-4 {
    margin-top: 20px;
}
.mt-3 {
    margin-top: 15px;
}
.mt-2 {
    margin-top: 10px;
}
.mt-1 {
    margin-top: 5px;
}
.mb-5 {
    margin-bottom: 25px;
}
.mb-4 {
    margin-bottom: 20px;
}
.mb-3 {
    margin-bottom: 15px;
}
.mb-2 {
    margin-bottom: 10px;
}
.mb-1 {
    margin-bottom: 5px;
}
.mx-5 {
    margin: 0 25px;
}
.mx-4 {
    margin: 0 20px;
}
.mx-3 {
    margin: 0 15px;
}
.mx-2 {
    margin: 0 10px;
}
.mx-1 {
    margin: 0 5px;
}
.m-5 {
    margin: 25px;
}
.m-4 {
    margin: 20px;
}
.m-3 {
    margin: 15px;
}
.m-2 {
    margin: 10px;
}
.m-1 {
    margin: 5px;
}
.content-menu-float {
    position: absolute;
    background: #fff;
    border: solid 1px #eee;
    box-shadow: -1px 1px 5px #eee;
    z-index: 12;
    padding: 0;
    right: 10px;
    top: 25px;
    min-width: 120px;
}
.menu-float {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.menu-item-float {
    padding: 0px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.menu-item-float a {
    padding: 13px 20px;
    text-align: center;
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    text-decoration: none;
    transition: all 0.3s ease;
}

.menu-item-float a:hover {
    background: #eee;
    color: inherit;
}

.button-menu-dark {
    outline: none !important;
    color: #fff !important;
    background: #3da7c3;
    padding: 10px 20px;
    border: solid 1px #3da7c3;
    transition: all 0.3s ease;
    font-size: 14px;
}
.button-menu-light {
    outline: none !important;
    color: #3da7c3 !important;
    background: transparent;
    padding: 5px 10px;
    transition: all 0.3s ease;
    line-height: 3px;
    padding: 16px 10px;
    cursor: pointer;
    font-size: 14px;
}
td,
tr {
    transition: all 0.3s ease;
}

.button-menu-dark:hover {
    color: #3da7c3 !important;
    background: #fff;
    border-color: #fff;
}
.button-menu-light:hover {
    color: #fff !important;
    background: #3da7c3;
}

.button-menu:hover {
    background: #6e7e8f;
}

.editorClassName {
    padding: 20px 10px;
    border: solid 1px #efefef;
    height: 300px;
    overflow: auto;
}

.DraftEditor-root {
    height: 95%;
}

.rdw-editor-toolbar {
    margin: 0;
}

.mail .form-control {
    padding: 20px 10px !important;
    margin: 5px 0;
    border-radius: 0;
    border: solid 1px #efefef;
    box-shadow: none;
    outline: none;
}
.form-control {
    font-size: 14px !important;
}

.mail {
    padding: 20px !important;
    box-sizing: border-box;
}

.border-none {
    border: none !important;
}

.text-top {
    vertical-align: top;
}
.text-middle {
    vertical-align: middle;
}

.height-25 {
    max-height: 25px !important;
    min-height: 25px !important;
    height: 25px !important;
}

.content-departments {
    background: #fafafa;
    border-radius: 5px;
    padding: 15px;
    max-height: 400px;
    overflow: auto;
}

.table * {
    vertical-align: middle !important;
}
.listados .table {
    min-width: 100%;
}

.img-estate {
    width: 100%;
    border: solid 2px #eee;
    border-radius: 5px;
    box-shadow: -3px 4px 5px #ccc;
}

.delete-icon:hover {
    color: #7d8996 !important;
}
.delete-icon {
    position: absolute;
    float: right;
    width: 60px;
    right: 0;
    top: 7px;
    color: #3da7c3;
    display: none;
    z-index: 999;
    transition: all 0.3s ease;
    text-decoration: none;
}

.note {
    background: #fbfbfb;
    border-radius: 5px;
    position: relative;
    padding: 15px 10px;
    color: #444;
}

.note:hover .delete-icon {
    display: block;
}

.error {
    color: #f00;
    width: 100%;
    margin: 30px 0;
    position: relative;
    float: left;
    text-align: center;
}
button {
    outline: none !important;
    box-shadow: none !important;
}
.title-body-modal {
    color: #666;
    font-weight: 300;
    font-size: 18px;
    text-align: center;
    width: 100%;
    margin: 10px 0 0;
}

.remove-contact {
    position: absolute;
    float: right;
    right: 25px;
    top: -5px;
    text-decoration: none;
    color: #888;
    border: none;
    background-color: #fff;
    text-align: center;
    -webkit-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    cursor: pointer;
    font-size: 14px;
    height: 20px;
    outline: none;
}

.content-table-budget {
    height: 280px !important;
    margin: 5px 1%;
    width: 98%;

    padding: 0 !important;
    border: none !important;
}

.content-table-budget thead {
    background: #3da7c3;
    color: #fff;
}

.danger {
    color: rgb(255, 73, 73);
}

.invalid {
    border: solid 2px rgb(243, 137, 137);
    border-radius: 3px;
}
.invalid-tab {
    background: #ff6c6c;
    color: #fff;
}

.loading-page {
    position: fixed;
    float: left;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 50;
    background: #fff9;
    height: 100vh;
    top: 0;
}

.loading-page .bp3-spinner {
    margin-top: 19%;
}

.datepicker .bp3-input {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.button-card-top {
    position: absolute;
    float: right;
    right: 15px;
    top: 8px;
}

.add-more-contacts {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 20px;
}

.link {
    color: #267dc0 !important;
    width: 100% !important;
    text-align: center !important;
    position: relative;
    float: left;
    margin: 0 auto !important;
}

.preview-link-participant * {
    color: #267dc0 !important;
    position: relative;
    top: 1px;
    left: 5px;
}

td:hover .preview-link-participant {
    display: block;
}

.preview-link-participant {
    display: none;
    position: absolute;
    right: 25%;
}

.modal-form button svg {
    margin-top: -5px !important;
}
.modal-form-mail button svg {
    margin-top: -5px !important;
}

.font-15 {
    font-size: 15px !important;
}

.uppercase {
    text-transform: uppercase;
}

.edit-address {
    cursor: pointer;
    color: #1f70a1;
    font-size: 12px;
    transition: all 0.3s ease;
}

.capitalize {
    text-transform: capitalize;
}

.card-estate {
    border: solid 1px #eee;
    padding: 10px 5px;
    text-align: center;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease;
}
.content-img-card-estate {
    width: 100%;
}
.img-card-estate {
    width: 50px !important;
}
.decription-card-estate {
    width: 100%;
    padding: 10px;
}

.card-estate:hover {
    border: solid 1px #7d8996;
}

.table p {
    margin: 0;
}

.appeanrence-unset {
    -webkit-appearance: unset !important;
    -moz-appearance: unset !important;
    -ms-appearance: unset !important;
    -o-appearance: unset !important;
    appearance: unset !important;
}

.table-head th {
    text-align: left !important;
}

.d-block {
    display: block;
}

.expanded-field {
    width: 200px;
    transition: all 0.3s ease;
    outline: none;
    padding: 4px 0 4px 10px;
    border-color: #ddd;
    box-shadow: none;
    position: relative;
    float: right;
    right: 0;
}
.expanded-field-label {
    width: 280px;
}

.expanded-field:focus {
    width: 360px !important;
}
@media screen and (max-width: 640px) {
    .expanded-field-label {
        width: 170px;
    }
    .expanded-field:focus {
        width: 230px !important;
    }
}

.actions-topbar a {
    position: relative;
    float: right;
    font-size: 15px;
    top: 5px;
}

.table-permissions {
    border: none;
    margin: 5px;
    width: 100%;
    background: #f4f9ff;
    box-shadow: 0 0 5px #eee;
}

@media print {
    .profile-bar,
    .content-tab,
    .button-menu-light,
    head,
    .top-card img,
    .documents-adjunts,
    .sidebar {
        display: none !important;
    }
    .container {
        width: 100% !important;
        left: 0 !important;
        padding: 0px 40px !important;
    }
}

.padding-button-permission {
    padding: 100px 0;
}
@media screen and (max-width: 768px) {
    .padding-button-permission {
        padding: 10px 0 !important;
    }
}

.icon-container-list {
    max-height: 20px;
    display: inline-block;
    width: 25px;
    text-align: center;
    display: inline-block;
}

.icon-container-list img {
    max-height: 18px;
    max-width: 18px;
}

.react-select__value-container--has-value {
    text-transform: capitalize !important;
}

.activity-indicator i {
    font-size: 12px;
}
.activity-indicator.warning {
    color: #ffb90e;
}
.activity-indicator.danger {
    color: #ff6e6e;
}
.activity-indicator.ok {
    color: #ababab;
}
.title-stage {
    display: inline-block;
}
.content-title-stage {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    z-index: 9000;
    margin-top: 15px;
}
.content-title-stage #arrow {
    position: absolute;
    top: 0;
    margin-left: -18px;
}
.content-stages #arrow:first-child {
    display: none;
}
.content-title-stage #arrow svg {
    max-height: 53px;
}
.content-stages {
    display: flex;
    flex-flow: column wrap;
    overflow: auto;
}
.target-controller {
    min-width: 150px;
    height: 45px;
    display: inline-block;
    width: 25%;
    border-radius: 4px;
    box-sizing: border-box;
    border: 2px solid;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding-top: 5px;
    margin: 1rem;
}
.target-controller.lost {
    color: #fff;
    background-color: red;
    border-color: #da0000;
}
.target-controller.won {
    color: #fff;
    background-color: green;
    border-color: #007100;
}
.target-controller.trash {
    color: grey;
    background-color: #eee;
    border-color: #e2e2e2;
}
.target-controller.change {
    color: grey !important;
    background-color: #fff;
    border-color: #e2e2e2;
    border-style: dashed;
}

.modal-backdrop {
    opacity: 0.5 !important;
}

.modal {
    opacity: 1 !important;
}

.color-white {
    color: #fff;
}

.profile .bp3-tab-list {
    padding-left: 15px;
    padding-top: 7px;
}

.sortable svg {
    pointer-events: none;
}
.d-inline {
    display: inline-block !important;
}
.sortTable {
    border: 1px solid #eee;
}

.sortTable .active {
    background-color: #e5e5e5;
}

.sortTable th span {
    display: inline-block;
}

.sortTable .sortable {
    cursor: pointer;
}
.d-inline {
    display: inline-block;
}
.profile .bp3-tab-list {
    padding-left: 15px;
    padding-top: 7px;
}
.bp3-tab[aria-selected='true'],
.bp3-tab:not([aria-disabled='true']):hover {
    outline: 0;
}
.bp3-tab-panel {
    margin-top: 8px;
    border-top: 1px solid rgba(0, 40, 100, 0.12);
}
.profile .documents {
    margin: 10px;
}
.documents {
    border: 1px solid #eee;
}
.document--item {
    padding: 5px 0px 5px 10px;
    border-bottom: 1px solid #eee;
    transition: all 0.3s;
}
.document--item.won {
    border-left: 2px solid #4caf50;
}
.won {
    color: #4caf50 !important;
}
.lost {
    color: #f94839 !important;
}
.white {
    background: #fff !important;
}
.document--item.open {
    border-left: 2px solid #1991eb;
}
.document--item.lost {
    border-left: 2px solid red;
}
.document--item:hover {
    box-shadow: 0px 0px 5px #00000012;
}
.document--item a,
.document--item button {
    color: rgb(44, 44, 44);
    opacity: 0.5;
    transition: all 0.3s;
}
.document--item a:hover,
.document--item button:hover {
    color: rgb(44, 44, 44);
    opacity: 1;
}
.additional-fields {
    padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    transition: all 0.3s;
}
.additional-fields:active {
    background-color: #eee;
}
/* Activity */

.bordered {
    padding: 10px 25px;
    border: 1px solid #f3f3f3;
}

.bordered a {
    margin-left: 15px;
    color: #007cab !important;
}

.flex-row {
    flex-direction: row !important;
}

.document--item {
    text-align: left;
}

.btn-secondary {
    color: #fff !important;
}

.card__profile--item {
    font-weight: 600 !important;
}

.asyncselect-containar .react-select__indicator {
    padding: 5px !important;
}

.asyncselect-containar .react-select__control {
    min-height: 30px;
}

.flex-content {
    display: flex;
    flex-flow: row nowrap;
}

.flex-content div {
    padding: 5px 25px;
}

.rdw-editor-main {
    height: 300px !important;
    overflow-y: auto !important;
}

.bp3-overlay {
    z-index: 9999999 !important;
}

.bp3-drawer-header .bp3-heading {
    font-size: 14px;
    font-weight: 300;
}

.current-admin * {
    color: #ce2424 !important;
}
